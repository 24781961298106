import logo from './logo.svg';
import './App.css';
import './style.css';
import dummy1 from './dummy-1.png';
import dummywh from './dummy-wh.png';
import * as fabric from 'fabric'; // v6
import { useState, useRef, useEffect } from 'react';
import DraggableList from "react-draggable-list";
import { Row, Col, Container, Modal, Button, Dropdown, Toast } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HexColorPicker } from "react-colorful";



const base_url = "https://sboedtemplateapi.uvanij.com/api/Animation/";

function App() {
  const canvaref = useRef(null);
  const [canvas, setCanvas] = useState(null);

  //Default {version: '6.0.2', objects: []}

  const [templateId, setTemplateId] = useState(null);

  const [templateJSON, setTemplateJSON] = useState(null);

  const [templateName, setTemplateName] = useState(null);

  const [templates, setTemplates] = useState([]);

  const [saveModal, setSaveModal] = useState(false);

  const [textModal, setTextModal] = useState(false);

  const [selected, setSelected] = useState(false);

  const defaultText = { fontFamily: 'arial', fontWeight: 'normal', fontSize: 10, fill: '#000000', angle: 0.0, textAlign: "center" };

  const [textFont, setTextFont] = useState(defaultText);

  const [imageModal, setImageModal] = useState(false);

  const defaultImage = { "angle": 0, "opacity": 100 };

  const [imageProp, setImageProp] = useState(defaultImage);

  const [animateOption, setAnimateOption] = useState(1);

  const containerRef = useRef();
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 700, height: 500 });

  const handleDimensionChange = (e, dimension) => {
    const value = parseInt(e.target.value, 10);
    setCanvasDimensions(prev => ({
      ...prev,
      [dimension]: value > 0 ? value : 0, // Ensure no negative or zero dimensions
    }));
  };

  useEffect(() => {
    const canvas = canvaref.current;
    if (canvas) {
      canvas.width = canvasDimensions.width;
      canvas.height = canvasDimensions.height;

    }
  }, [canvasDimensions]);

  const _onListChange = (newList) => {
    let json = canvas.toJSON(['idd']);
    json.objects = JSON.parse(JSON.stringify(newList));
    setTemplateJSON(json);
    canvas.clear();
    canvas.loadFromJSON(json, function () {
      canvas.renderAll.bind(canvas);
    });
  };

  const Item = ({ item, itemSelected, dragHandleProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const isSelected = canvas.getActiveObject() && canvas.getActiveObject().get('idd') == item.idd
    return (
      <div
        className={`disable-select ${isSelected ? 'selected-class' : ''}`}
        style={{
          border: "1px solid #fff",
          padding: "2px 1px",
          display: "flex",
          justifyContent: "space-around",
          background: "transparent",
          userSelect: "none",
          color: "#fff",
          height: "30px",
          cursor: 'pointer'
        }}

        onTouchStart={(e) => {
          e.preventDefault();
          console.log("touchStart");
          e.target.style.backgroundColor = "blue";
          document.body.style.overflow = "hidden";
          onTouchStart(e);
        }}
        onMouseDown={(e) => {
          console.log("mouseDown");
          document.body.style.overflow = "hidden";
          onMouseDown(e);
        }}
        onTouchEnd={(e) => {
          e.target.style.backgroundColor = "black";
          document.body.style.overflow = "visible";
        }}
        onMouseUp={() => {
          document.body.style.overflow = "visible";
        }}
      >
        {item.type == 'IText' ? 'TextBox' : 'Image'}<a href="#" style={{ paddingTop: '00px', display: 'inline-block', pointerEvents: 'auto' }} className="menu-anchor" onClick={() => { e.preventDefault(); e.stopPropagation(); deleteObject() }} title="Removed">

          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 12V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </a>
      </div>
    );
  };

  const fontFamilyArray = ['Arial', 'Arial Black', 'Bahnschrift', 'Calibri', 'Cambria', 'Cambria Math', 'Candara', 'Comic Sans MS', 'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Ebrima', 'Franklin Gothic Medium', 'Gabriola', 'Gadugi', 'Georgia', 'HoloLens MDL2 Assets', 'Impact', 'Ink Free', 'Javanese Text', 'Leelawadee UI', 'Lucida Console', 'Lucida Sans Unicode', 'Malgun Gothic', 'Marlett', 'Microsoft Himalaya', 'Microsoft JhengHei', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le', 'Microsoft YaHei', 'Microsoft Yi Baiti', 'MingLiU-ExtB', 'Mongolian Baiti', 'MS Gothic', 'MV Boli', 'Myanmar Text', 'Nirmala UI', 'Palatino Linotype', 'Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Historic', 'Segoe UI Emoji', 'Segoe UI Symbol', 'SimSun', 'Sitka', 'Sylfaen', 'Symbol', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Webdings', 'Wingdings', 'Yu Gothic'];

  useEffect(() => {
    const canva = new fabric.Canvas(canvaref.current, {
      "preserveObjectStacking": true
    });
    canva.on("selection:created", function (obj) {
      if (canva.getActiveObject().get('type') == 'i-text') {
        let fontFamily = {
          fontFamily: canva.getActiveObject().fontFamily,
          fontWeight: canva.getActiveObject().fontWeight,
          fontSize: canva.getActiveObject().fontSize,
          angle: canva.getActiveObject().angle,
          fill: canva.getActiveObject().fill,
          textAlign: canva.getActiveObject().textAlign
        }
        setTextFont(fontFamily);
        setSelected(true);
        setTextModal(true);
        setImageModal(false);

      }
      if (canva.getActiveObject().get('type') == 'image') {
        let fontFamily = {
          angle: canva.getActiveObject().angle,
          opacity: canva.getActiveObject().opacity * 100
        }
        console.log("fontFamily", fontFamily);
        setImageProp(fontFamily);
        setImageModal(true);
        setTextModal(false);
      }
    });

    canva.on("selection:updated", function (obj) {
      if (canva.getActiveObject().get('type') == 'i-text') {
        let fontFamily = {
          fontFamily: canva.getActiveObject().fontFamily,
          fontWeight: canva.getActiveObject().fontWeight,
          fontSize: canva.getActiveObject().fontSize,
          angle: canva.getActiveObject().angle,
          fill: canva.getActiveObject().fill,
          textAlign: canva.getActiveObject().textAlign
        }
        setTextFont(fontFamily);
        setSelected(true);
        setTextModal(true);
        setImageModal(false);
      }
      if (canva.getActiveObject().get('type') == 'image') {
        let fontFamily = {
          angle: canva.getActiveObject().angle,
          opacity: canva.getActiveObject().opacity * 100
        }
        console.log("fontFamily", fontFamily);
        setImageProp(fontFamily);
        setImageModal(true);
        setTextModal(false);
      }
    });


    setCanvas(canva);
    fetchTemplates();
    return () => {
      setCanvas(null);
      canva.dispose();
    }
  }, []);

  useEffect(() => {
    let temp1 = JSON.parse(JSON.stringify(templateJSON));
    if (canvas && canvas.getActiveObject() != undefined && temp1 && temp1.objects.length > 0) {
      let temp = canvas.toJSON(['idd']);
      let temp1 = JSON.parse(JSON.stringify(templateJSON));
      let filterIndex = null;
      temp1.objects.map((t, index) => t.idd == canvas.getActiveObject().get('idd') ? filterIndex = index : '');
      if (filterIndex) {
        temp1.objects[filterIndex] = { ...temp.objects[filterIndex], idd: canvas.getActiveObject().get('idd') };
      }
      setTemplateJSON(temp1);
    }
  }, [JSON.parse(JSON.stringify(canvas?.getActiveObject() || {}))])




  const fetchTemplates = () => {
    axios.post(`${base_url}GetUserTemplateData`, {
      "UserTemplateId": "",
      "UserId": 1
    }).then(res => {
      if (res.data.isSuccess) {
        setTemplates(res.data.data);
      }
      else {
        setTemplates([]);
      }
    }).catch(error => toast.error("Something Went Wrong!"))
  }

  const openTemplate = (t) => {
    setTemplateId(t.userTemplateId);
    setTemplateName(t.templateName);
    var json = JSON.parse(t.templateJson);
    json.objects.map((j, index) => !j.idd ? j.idd = String(index + 1) : '');
    setTemplateJSON(json);
    console.log("json", json)
    canvas.clear();
    canvas.loadFromJSON(json, function () {
      canvas.renderAll.bind(canvas);
    });
  }

  const openNewTemplate = () => {
    setTemplateId(null);
    setTemplateJSON(null);
    setTemplateName(null);
    canvas.clear();
  }

  const saveTemplate = () => {
    axios.post(`${base_url}AddEditUserTemplate`,
      {
        "UserTemplateId": templateId || '',
        "TemplateName": templateName,
        "TemplateType": "Email",
        "TemplateJson": JSON.stringify(canvas.toJSON(['idd'])),
        "UserId": 1,
        "CreatedBy": 1,
        "UpdatedBy": 2,
        "IsActive": true
      }
    ).then(res => {
      console.log(res)
      if (res.data.isSuccess) {
        toast.success(res.data.data.result_message);
        setSaveModal(false);
        fetchTemplates();
      }
      else {
        toast.error("Something went wrong");
      }
    }).catch(error => toast.error("Something Went Wrong!"))
  }

  const grow = (x, y) => {
    var valueX = Math.round(Math.pow(x, 2));
    var valueY = Math.round(Math.pow(y, 2));

    canvas.getActiveObject().animate(
      { height: valueY, width: valueX },
      {
        duration: 1000,
        easing: fabric.util.ease.easeInSine,
        onChange: canvas.renderAll.bind(canvas),
      }
    );
  }

  const shrink = (x, y) => {
    var shrinkX = Math.round(canvas.getActiveObject().getScaledWidth() / 3);
    var shrinkY = Math.round(canvas.getActiveObject().getScaledHeight() / 3);
    canvas.getActiveObject().animate(
      { height: shrinkY, width: shrinkX },
      {
        duration: 1600,
        easing: fabric.util.ease.easeInSine,
        onChange: canvas.renderAll.bind(canvas),
      }
    );
  }

  // const animateObject = () => {
  //   if (canvas.getActiveObject() == undefined) {
  //     setSelected(false);
  //     setImageModal(false);
  //     setTextModal(false);
  //     return toast.error("Plese Select a Object");
  //   }
  //   console.log("animateOption", animateOption);
  //   if (animateOption == '1') {
  //     console.log("inside 1");
  //     var valueX = canvas.getActiveObject().getScaledWidth();
  //     var valueY = canvas.getActiveObject().getScaledHeight();

  //     // Using the animate method and passing scaleY property
  //     canvas.getActiveObject().animate({ top: "200" }, {
  //       onChange: canvas.renderAll.bind(canvas),
  //       easing: fabric.util.ease.easeInCubic,
  //       duration: 1000,
  //     });
  //     setTimeout(() => {
  //       canvas.getActiveObject().animate({ top: "0" }, {
  //         onChange: canvas.renderAll.bind(canvas),
  //         easing: fabric.util.ease.easeInCubic,
  //         duration: 1000,
  //       });
  //     }, 1000);

  //   }
  //   console.log("canvas.getActiveObject().left", canvas.getActiveObject().left);
  //   if (animateOption == '2') {
  //     console.log("canvas.getActiveObject().left", canvas.getActiveObject().left);
  //     var valueX = canvas.getActiveObject().getScaledWidth();
  //     var valueY = canvas.getActiveObject().getScaledHeight();
  //     canvas.getActiveObject().animate({ height: valueY, width: canvas.getActiveObject().left }, {
  //       duration: 1000,
  //       onChange: canvas.renderAll.bind(canvas),
  //       easing: fabric.util.ease['easeInOutBounce']
  //     });
  //     setTimeout(() => canvas.getActiveObject().animate({ height: valueY, width: valueX }, {
  //       duration: 1000,
  //       onChange: canvas.renderAll.bind(canvas),
  //       easing: fabric.util.ease['easeInOutBounce']
  //     }), 2000);
  //   }

  //   if (animateOption == '3') {
  //     console.log("inside 1");
  //     var valueX = canvas.getActiveObject().getScaledWidth();
  //     var valueY = canvas.getActiveObject().getScaledHeight();

  //     // Using the animate method and passing scaleY property
  //     canvas.getActiveObject().animate({ opacity: 0 }, {
  //       onChange: canvas.renderAll.bind(canvas),
  //       easing: fabric.util.ease.easeInCubic,
  //       duration: 3000,
  //     });
  //     setTimeout(() => {
  //       canvas.getActiveObject().animate({ opacity: 1 }, {
  //         onChange: canvas.renderAll.bind(canvas),
  //         easing: fabric.util.ease.easeInCubic,
  //         duration: 3000,
  //       });
  //     }, 3000);

  //   }
  // }
  const bezierPoint = (t, p0, p1, p2, p3) => {
    const x = Math.pow(1 - t, 3) * p0.x +
      3 * Math.pow(1 - t, 2) * t * p1.x +
      3 * (1 - t) * Math.pow(t, 2) * p2.x +
      Math.pow(t, 3) * p3.x;

    const y = Math.pow(1 - t, 3) * p0.y +
      3 * Math.pow(1 - t, 2) * t * p1.y +
      3 * (1 - t) * Math.pow(t, 2) * p2.y +
      Math.pow(t, 3) * p3.y;

    return { x, y };
  };

  // Function to generate points along the Bezier path
  const getBezierPoints = (steps) => {
    const p0 = { x: 0, y: 200 }; // Start point
    const p1 = { x: 200, y: 100 }; // Control point 1
    const p2 = { x: 600, y: 300 }; // Control point 2
    const p3 = { x: 1000, y: 200 }; // End point (beyond canvas)

    const points = [];
    for (let i = 0; i <= steps; i++) {
      const t = i / steps; // Calculate the ratio (0 to 1)
      points.push(bezierPoint(t, p0, p1, p2, p3));
    }
    return points;
  };


  const animateObjectlefttoright = (object, index) => {

    const startX = object.left;
    const endX = canvas.width - object.getScaledWidth();

    object.animate({ left: endX }, {
      onChange: canvas.renderAll.bind(canvas),
      easing: fabric.util.ease.easeInOutQuad,
      duration: 2000,
    });

    // setTimeout(() => {
    //   object.animate({ left: startX }, {
    //     onChange: canvas.renderAll.bind(canvas),
    //     easing: fabric.util.ease.easeInOutQuad,
    //     duration: 2000,
    //   });
    // }, 2000); // Delay the return to left for 2 seconds
  }

  const animateObjectrighttoleft = (object, index) => {

    const startX = object.left;
    const endX = canvas.width - object.getScaledWidth(); // Target position (right edge)

    setTimeout(() => {
      object.animate({ left: 0 }, {
        onChange: canvas.renderAll.bind(canvas),
        easing: fabric.util.ease.easeInOutQuad, // Smooth easing
        duration: 2000, // Animation duration for each object
      });
    }, index * 2000);

    // setTimeout(() => {
    //   canvas.getActiveObject().animate({ left: startX }, {
    //     onChange: canvas.renderAll.bind(canvas),
    //     easing: fabric.util.ease.easeInOutQuad,
    //     duration: 2000,
    //   });
    // }, 2000);
  }

  const animateObjectlefttorightCurve = (object, duration, delay) => {

    const svgPathString = 'M 0 200 C 200 100, 600 300, 800 200';
    const path = new fabric.Path(svgPathString, {
      fill: '',
      stroke: 'transparent',
      strokeWidth: 1,
    });
    //canvas.add(path);

    const steps = 1000;
    const points = getBezierPoints(steps);
    setTimeout(() => {
      const totalSteps = points.length;
      let currentStep = 0;
      const endX = canvas.width - object.getScaledWidth();

      const animate = () => {
        if (currentStep < totalSteps) {
          const { x, y } = points[currentStep];
          if (x >= endX) {
            object.set({ left: endX, top: y });
            canvas.renderAll();
            return; // Stop animation when reaching endX
          }

          object.set({ left: x, top: y });
          canvas.renderAll();
          currentStep++;
          requestAnimationFrame(animate);
        }
      };
      animate();
    }, delay);
  }

  const animateObject = () => {
    const objects = canvas.getObjects();

    if (objects === undefined) {
      setSelected(false);
      setImageModal(false);
      setTextModal(false);
      return toast.error("Please Select an Object");
    }

    // Animate right to left 
    if (animateOption == '1') {
      if (canvas.getActiveObject() === undefined) {
        objects.forEach((object, index) => {
          animateObjectrighttoleft(object, index);
        });
      }
    else {
      animateObjectrighttoleft(canvas.getActiveObject(), 1)
    }
  }
  // Animate left to right 
  if (animateOption == '2') {
    if (canvas.getActiveObject() === undefined) {
      objects.forEach((object, index) => {
        animateObjectlefttoright(object, index);
      });
    }
    else {
      animateObjectlefttoright(canvas.getActiveObject(), 1);
    }
  }
  // Move the object up and down
  if (animateOption === '3') {
    console.log("Inside Option 1: Vertical Move Animation");
    const valueX = canvas.getActiveObject().getScaledWidth();
    const valueY = canvas.getActiveObject().getScaledHeight();

    // Move the object to 'top: 200' with easing
    canvas.getActiveObject().animate({ top: 10 }, {
      onChange: canvas.renderAll.bind(canvas),
      easing: fabric.util.ease.easeInCubic,
      duration: 1000, // Animation duration
    });

    // Move the object back to 'top: 0' after 1 second
    setTimeout(() => {
      canvas.getActiveObject().animate({ top: 0 }, {
        onChange: canvas.renderAll.bind(canvas),
        easing: fabric.util.ease.easeInCubic,
        duration: 1000,
      });
    }, 1000);
  }
  // Change opacity (fade in/out)
  if (animateOption === '4') {
    console.log("Inside Option 3: Opacity Animation");
    const valueX = canvas.getActiveObject().getScaledWidth();
    const valueY = canvas.getActiveObject().getScaledHeight();

    // Fade the object out (opacity to 0)
    canvas.getActiveObject().animate({ opacity: 0 }, {
      onChange: canvas.renderAll.bind(canvas),
      easing: fabric.util.ease.easeInCubic,
      duration: 3000, // Slow fade-out
    });

    // Fade the object back in (opacity to 1) after 3 seconds
    setTimeout(() => {
      canvas.getActiveObject().animate({ opacity: 1 }, {
        onChange: canvas.renderAll.bind(canvas),
        easing: fabric.util.ease.easeInCubic,
        duration: 3000, // Slow fade-in
      });
    }, 3000);
  }
  // Move the object left to right in curved path
  if (animateOption === '5') {
    if (canvas.getActiveObject() === undefined) {
    objects.forEach((object, index) => {
      animateObjectlefttorightCurve(object, 4000, index * 2000);
    });
  }
  else{
    animateObjectlefttorightCurve(canvas.getActiveObject(), 4000, 2000);
  }
  }
  // Option 2: Change width (resize animation)
  if (animateOption === '6') {
    console.log("Inside Option 2: Resize Animation");
    const valueX = canvas.getActiveObject().getScaledWidth();
    const valueY = canvas.getActiveObject().getScaledHeight();

    // Resize the object by reducing its width to 'left'
    canvas.getActiveObject().animate({ height: 10, width: canvas.getActiveObject().left }, {
      duration: 1000,
      onChange: canvas.renderAll.bind(canvas),
      easing: fabric.util.ease.easeInOutBounce, // Bounce effect for resizing
    });

    // Restore the original width after 2 seconds
    setTimeout(() => {
      canvas.getActiveObject().animate({ height: 10, width: 0 }, {
        duration: 1000,
        onChange: canvas.renderAll.bind(canvas),
        easing: fabric.util.ease.easeInOutBounce,
      });
    }, 2000);
  }
};

const addElement = (img) => {
  let reader = new FileReader();
  reader.readAsDataURL(img);
  reader.onload = (e) => {
    let imageUrl = e.target.result;
    let imageElement = document.createElement('img');
    imageElement.src = imageUrl;
    imageElement.onload = () => {
      let json = canvas.toJSON(['idd']);
      let image = new fabric.Image(imageElement, { idd: String((json.objects?.length || 0) + 1) });
      console.log("image", image);
      canvas.add(image);
      canvas.centerObject(image);
      canvas.setActiveObject(image);
      canvas.renderAll();
      console.log("getactiveobject", canvas.getActiveObject().get('idd'));
      let temp = canvas.toJSON(['idd']);
      console.log("TEMP_DEEP_1", canvas);
      console.log("temp_deep", temp)
      let temp1 = JSON.parse(JSON.stringify(templateJSON));
      if (!temp1) {
        temp1 = { ...temp, objects: [] }
      }
      temp1.objects.push({ ...temp.objects[temp.objects.length - 1], idd: canvas.getActiveObject().get('idd') });
      setTemplateJSON(temp1);
    }
  }

}


const addText = (obj) => {
  let textFont1 = {};
  if (obj && obj.fontFamily) {
    textFont1 = obj;
  }
  else {
    textFont1 = textFont;
  }
  let json = canvas.toJSON(['idd']);
  if (selected && canvas.getActiveObject() == undefined) {
    setSelected(false);
    setImageModal(false);
    setTextModal(false);
    return toast.error("Plese Select a Object");
  }

  if (!selected) {
    let temp1 = JSON.parse(JSON.stringify(templateJSON));
    let text = new fabric.IText('Demo text', {
      evented: true,
      selectable: true,
      editable: true,
      fontFamily: textFont1.fontFamily,
      fontWeight: textFont1.fontWeight,
      fontSize: textFont1.fontSize,
      angle: textFont1.angle,
      fill: textFont1.fill,
      textAlign: textFont1.textAlign,
      idd: String((json.objects?.length || 0) + 1)

    });

    canvas.add(text);
    canvas.renderAll();
    let temp = canvas.toJSON(['idd']);
    if (!temp1) {
      temp1 = { ...temp, objects: [] }
    }
    temp1.objects.push({ ...temp.objects[temp.objects.length - 1], idd: String((json.objects?.length || 0) + 1) });
    setTextFont(defaultText);
    setTemplateJSON(temp1);
  }
  else {
    canvas.getActiveObject().fontFamily = textFont1.fontFamily;
    canvas.getActiveObject().fontWeight = textFont1.fontWeight;
    canvas.getActiveObject().fontSize = textFont1.fontSize;
    canvas.getActiveObject().angle = textFont1.angle;
    canvas.getActiveObject().fill = textFont1.fill;
    canvas.getActiveObject().textAlign = textFont1.textAlign;
    canvas.renderAll();
    setTimeout(() => {
      let temp1 = JSON.parse(JSON.stringify(templateJSON));
      let temp = canvas.toJSON(['idd']);
      let filterIndex = null;
      temp1.objects.map((t, index) => t.idd == canvas.getActiveObject().get('idd') ? filterIndex = index : '');
      if (filterIndex) {
        temp1.objects[filterIndex] = { ...temp.objects[filterIndex], idd: canvas.getActiveObject().get('idd') };
      }
      setTemplateJSON(temp1);
    }, 500);
  }
  setSelected(true);

}

const updateImage = (obj) => {
  let obj1;
  if (obj && obj.opacity) {
    obj1 = obj;
  }
  else {
    obj1 = imageProp;
  }

  if (canvas.getActiveObject() == undefined) {
    setSelected(false);
    setImageModal(false);
    setTextModal(false);
    return toast.error("Plese Select a Object");
  }
  canvas.getActiveObject().angle = obj1.angle;
  canvas.getActiveObject().opacity = obj1.opacity / 100;
  canvas.renderAll();
  setTimeout(() => {
    let temp1 = JSON.parse(JSON.stringify(templateJSON));
    let temp = canvas.toJSON(['idd']);
    let filterIndex = null;
    temp1.objects.map((t, index) => t.idd == canvas.getActiveObject().get('idd') ? filterIndex = index : '');
    if (filterIndex) {
      temp1.objects[filterIndex] = { ...temp.objects[filterIndex], idd: canvas.getActiveObject().get('idd') };
    }
    setTemplateJSON(temp1);
  }, 500);
}


const download = () => {
  if (canvas.toJSON(['idd']).objects.length == 0) {
    alert("Please add any elements to the below canvas to download pic");
    return false;
  }
  let downloadLink = document.createElement('a');
  downloadLink.setAttribute('download', 'CanvasAsImage.png');
  let dataURL = canvas.toDataURL('image/png');
  let url = dataURL.replace(/^data:image\/png/, 'data:application/octet-stream');
  downloadLink.setAttribute('href', url);
  downloadLink.click();
}

const consoleJSON = () => {
  console.log(canvas.toJSON(['idd']));
  alert(JSON.stringify(canvas.toJSON(['idd'])));
}

const closeModal = () => {
  setSaveModal(false);
}

const deleteObject = () => {
  if (canvas.getActiveObject() == undefined) {
    setSelected(false);
    setImageModal(false);
    setTextModal(false);
    return toast.error("Plese Select a Object");
  }
  let temp1 = JSON.parse(JSON.stringify(templateJSON));
  let temp = { ...temp1, objects: [] };
  let filterIndex = null;
  temp1.objects.map((t, index) => t.idd == canvas.getActiveObject().get('idd') ? filterIndex = index : '');
  if (filterIndex) {
    temp1.objects.map((t, index) => index != filterIndex ? temp.objects.push(t) : '')
  }
  setTemplateJSON(temp);
  canvas.remove(canvas.getActiveObject());
}

const setFontData = (value, label) => {
  console.log("label", label);
  console.log("value", value);

  let temp = JSON.parse(JSON.stringify(textFont));
  temp[label] = value;
  setTextFont(temp);
  if (selected) {
    addText(temp);
  }
}

const setImageData = (value, label) => {
  let temp = JSON.parse(JSON.stringify(imageProp));
  temp[label] = value
  setImageProp(temp);
  updateImage();
}

return (
  <>
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container-fluid">
        <a className="navbar-brand me-4" href="#">LOGO</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item pr-4">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Select Template
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {templates.length > 0 ? (
                    templates.map((t) => (
                      <Dropdown.Item
                        key={t.templateName} // Ensure templateName is unique
                        onClick={() => openTemplate(t)}
                        style={{ cursor: "pointer" }}
                      >
                        {t.templateName}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item disabled>
                      Loading...
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Options
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => openNewTemplate()}>New Template</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSaveModal(true)}>Save Template</Dropdown.Item>
                  <Dropdown.Item onClick={() => { setTextModal(true); setImageModal(false); setSelected(false); }}>Add Text </Dropdown.Item>
                  <Dropdown.Item href="#">Publish</Dropdown.Item>
                  <Dropdown.Item name="toJSON" onClick={() => consoleJSON()}>Convert to JSON</Dropdown.Item>
                  <Dropdown.Item onClick={() => download()}>Download Poster</Dropdown.Item>
                  <Dropdown.Item onClick={() => deleteObject()}>Delete Selected Object </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">File name: {templateName || 'My Design'}</a>
            </li>
          </ul>
          <span className="navbar-text">
            <span className="profile-d">DE</span>
          </span>
        </div>
      </div>
    </nav>

    <div className="row m-0">
      <div className="col-md-3 p-0">
        <div className="row">
          <div className="col-md-3 pe-0">
            <div className="black-sm-pannel">
              <a href="#" className="menu-anchor" title="Choose Image" onClick={() => document.getElementById('fileInput').click()}>
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16" cy="8" r="2" stroke="#ffffff" strokeWidth="1.5" />
                  <path
                    d="M5 13.307L5.81051 12.5542C6.73658 11.6941 8.18321 11.7424 9.04988 12.6623L11.6974 15.4727C12.2356 16.0439 13.1166 16.1209 13.7457 15.6516C14.6522 14.9753 15.9144 15.0522 16.7322 15.8334L19 18"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </a>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                className="form-control"
                name="myImage"
                accept=".jpg,.png,.jpeg"
                onChange={(e) => addElement(e.target.files[0])}
              />

              <a href="#" className="menu-anchor" onClick={() => { setTextModal(true); setImageModal(false); setSelected(false); }} title="Add Text">
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M11.25 17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17H11.25ZM15.25 9.75C15.25 10.1642 15.5858 10.5 16 10.5C16.4142 10.5 16.75 10.1642 16.75 9.75H15.25ZM7.25 9.75C7.25 10.1642 7.58579 10.5 8 10.5C8.41421 10.5 8.75 10.1642 8.75 9.75H7.25ZM15.7071 7.32544L16.2646 6.82371V6.82371L15.7071 7.32544ZM9.5 16.25C9.08579 16.25 8.75 16.5858 8.75 17C8.75 17.4142 9.08579 17.75 9.5 17.75V16.25ZM15 17.75C15.4142 17.75 15.75 17.4142 15.75 17C15.75 16.5858 15.4142 16.25 15 16.25V17.75ZM10 7.75H12V6.25H10V7.75ZM12 7.75H14V6.25H12V7.75ZM12.75 17V7H11.25V17H12.75ZM15.25 9.22222V9.75H16.75V9.22222H15.25ZM7.25 9.22222V9.75H8.75V9.22222H7.25ZM14 7.75C14.4949 7.75 14.7824 7.75196 14.9865 7.78245C15.0783 7.79617 15.121 7.8118 15.1376 7.8194C15.148 7.82415 15.1477 7.82503 15.1496 7.82716L16.2646 6.82371C15.96 6.4853 15.579 6.35432 15.2081 6.29891C14.8676 6.24804 14.4479 6.25 14 6.25V7.75ZM16.75 9.22222C16.75 8.71757 16.7513 8.27109 16.708 7.91294C16.6629 7.54061 16.559 7.15082 16.2646 6.82371L15.1496 7.82716C15.1523 7.83015 15.1609 7.83939 15.1731 7.87221C15.1873 7.91048 15.2048 7.97725 15.2188 8.09313C15.2487 8.34011 15.25 8.67931 15.25 9.22222H16.75ZM10 6.25C9.55208 6.25 9.13244 6.24804 8.79192 6.29891C8.42102 6.35432 8.04 6.4853 7.73542 6.82371L8.85036 7.82716C8.85228 7.82503 8.85204 7.82415 8.86242 7.8194C8.87904 7.8118 8.92168 7.79617 9.01354 7.78245C9.21765 7.75196 9.50511 7.75 10 7.75V6.25ZM8.75 9.22222C8.75 8.67931 8.75129 8.34011 8.78118 8.09313C8.7952 7.97725 8.81273 7.91048 8.8269 7.87221C8.83905 7.83939 8.84767 7.83015 8.85036 7.82716L7.73542 6.82371C7.44103 7.15082 7.3371 7.54061 7.29204 7.91294C7.24871 8.27109 7.25 8.71757 7.25 9.22222H8.75ZM9.5 17.75H15V16.25H9.5V17.75Z"
                    fill="#ffffff"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="col-md-9 ps-0">
            <div className="dummy-imgsection">
              <img src={dummy1} className="w-100 sm-thumb" alt="Dummy 1" />
              <img src={dummywh} className="w-100 sm-thumb" alt="Dummy 2" />
              <img src={dummywh} className="w-100 sm-thumb" alt="Dummy 3" />

              <div className="text-center">
                <i className="fa fa-plus plus-size"></i>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="col-md-7 p-0">
        <div className="middle-section">
          <div className="middle-sectionhead">
            <h3>{templateId ? templateName : "Working On New Template"}</h3>
          </div>
          <div className="midle-sec-img">
            <canvas
              ref={canvaref}
            />
          </div>
        </div>
      </div>

      <div className="col-md-2 p-0" style={{ display: "none" }}>
        {templateJSON && templateJSON.objects.length > 0 &&
          <div className="App">
            <div
              ref={containerRef}
              style={{ touchAction: "pan-y", background: "beige" }}
            >
              <DraggableList
                itemKey="idd"
                template={Item}
                list={templateJSON.objects}
                onMoveEnd={(newList) => _onListChange(newList)}
                container={() => containerRef.current}
              />
              {/* {list.map((item) => (
            <Item item={item} />
          ))} */}
            </div>
          </div>
        }
      </div>

      <div className="col-md-2 p-0">
        <div className="right-panel">
          <div className="properties-section">
            <h4>Properties</h4>
            <div className="property-item">
              <label className='newlabel'>Hight</label>
              <input class="newinput" type="number" name="fontSize" list="fontSize" value={canvasDimensions.height}
                onChange={(e) => handleDimensionChange(e, 'height')} />
            </div>
            <div className="property-item">
              <label className='newlabel'>Width</label>
              <input class="newinput" type="number" name="fontSize" list="fontSize" value={canvasDimensions.width}
                onChange={(e) => handleDimensionChange(e, 'width')} />
            </div>
            {textModal ?
              <div className='text-property'>
                <div className="property-item">
                  <label className='newlabel'>Font Family</label>
                  <select className='newselect' value={textFont.fontFamily} onChange={(e) => setFontData(e.target.value, 'fontFamily')}>
                    {fontFamilyArray.map((f) => <option value={f.toLowerCase()}>{f}</option>)}
                  </select>
                </div>
                <div className="property-item">
                  <label className='newlabel'>Font Size</label>
                  <input class="newinput" type="number" name="fontSize" list="fontSize" value={textFont.fontSize} onChange={(e) => setFontData(e.target.value, 'fontSize')} />
                </div>
                <div className="property-item">
                  <label className='newlabel'>Font Weight</label>
                  <select className='newselect' value={textFont.fontWeight} onChange={(e) => setFontData(e.target.value, 'fontWeight')}>
                    <option value={"normal"}>{"Normal"}</option>
                    <option value={"bold"}>{"Bold"}</option>
                  </select>
                </div>
                <div className="property-item">
                  <label className='newlabel'>Text Align</label>
                  <select className='newselect' value={textFont.textAlign} onChange={(e) => setFontData(e.target.value, 'textAlign')}>
                    <option value={"left"}>{"Left"}</option>
                    <option value={"center"}>{"Center"}</option>
                    <option value={"right"}>{"Right"}</option>
                    <option value={"justify"}>{"Justify"}</option>
                    <option value={"justify-left"}>{"Justify-left"}</option>
                    <option value={"justify-center"}>{"Justify-center"}</option>
                    <option value={"justify-right"}>{"Justify-right"}</option>
                  </select>
                </div>
                <div className="property-item">
                  <label className='newlabel'>Color</label>
                  <HexColorPicker color={textFont.fill} onChange={(e) => setFontData(e, 'fill')} />
                </div>
                <div className="property-item">
                  <label className='newlabel'>Angle</label>
                  <input class="newinput" type="number" name="angle" list="angle" value={textFont.angle} onChange={(e) => setFontData(e.target.value, 'angle')} />
                </div>

                <button style={{ display: "none" }} className="middle-headbutton" onClick={() => { setTextFont(defaultText); setTextModal(false); setSelected(false); deleteObject() }}>Clear</button>
                {!selected ? <button className="middle-headbutton save-button" onClick={() => addText()}>Add Text</button> : ''}
              </div> : ''}
            {imageModal ?
              <div className='img-property'>
                <div className="property-item">
                  <label className='newlabel'>Please select the angle</label>
                  <input className='newinput' type="number" name="angle" list="angle" value={imageProp.angle} onChange={(e) => setImageData(e.target.value, 'angle')} />
                </div>
                <div className="property-item">
                  <label className='newlabel'>Opacity</label>
                  <input className='newinput' type="number" name="opacity" list="opacity" value={imageProp.opacity} onChange={(e) => setImageData(e.target.value, 'opacity')} />
                </div>
                <button style={{ display: "none" }} className="middle-headbutton" onClick={() => { setImageProp(defaultImage); setImageModal(false); deleteObject() }}>Clear</button>
              </div> : ''}
          </div>
          <div className="layers-section">
            <h4 className="layers-header">Annimate</h4>
            <div className="property-item">
              <select className='newselect_Animate' value={animateOption} onChange={(e) => setAnimateOption(e.target.value)}>
                <option value={1}>{"right to left"}</option>
                <option value={2}>{"left to right"}</option>
                <option value={3}>{"up and down"}</option>
                <option value={4}>{"opacity"}</option>
                <option value={5}>{"curved"}</option>
              </select>
              <a href="#" style={{ paddingTop: '00px', display: 'inline-block' }} className="menu-anchor" onClick={() => animateObject()} title="Run Animation">

                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="30px" height="30px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xmlSpace="preserve">
                  <g>
                    <path fill="#fff" stroke="white" strokeWidth="1" d="M16,0C7.178,0,0,7.178,0,16s7.178,16,16,16s16-7.178,16-16S24.822,0,16,0z M16,31C7.729,31,1,24.271,1,16
		S7.729,1,16,1s15,6.729,15,15S24.271,31,16,31z"/>
                    <path fill="#fff" stroke="white" strokeWidth="1" d="M19.609,14.802L14.316,10.8c-0.263-0.197-0.567-0.302-0.88-0.302C12.631,10.498,12,11.158,12,12v8
		c0,0.842,0.635,1.502,1.445,1.502c0.313,0,0.619-0.104,0.885-0.304l5.258-3.998c0.382-0.287,0.611-0.742,0.611-1.218
		C20.198,15.512,19.983,15.081,19.609,14.802z M18.985,16.401l-5.258,3.998C13.434,20.622,13,20.453,13,20v-8
		c0-0.326,0.225-0.502,0.437-0.502c0.096,0,0.19,0.034,0.279,0.101l5.293,4.002c0.165,0.123,0.19,0.292,0.19,0.382
		C19.198,16.147,19.122,16.3,18.985,16.401z"/>
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className="layers-section">
            <h4 className="layers-header">Layers</h4>
            <div className="layers-list">
              {templateJSON && templateJSON.objects.length > 0 ? (
                <DraggableList
                  itemKey="idd"
                  template={Item}
                  list={templateJSON.objects}
                  onMoveEnd={(newList) => _onListChange(newList)}
                  container={() => containerRef.current}
                />
              ) : (
                <p className="no-layers-message">No layers available</p>
              )}
            </div>
          </div>


        </div>
      </div>
    </div>

    <ToastContainer />
    {
      saveModal ?
        <div
          className="modal show"
          style={{ display: 'block', position: 'initial' }}
        >
          <Modal show={saveModal} onHide={() => setSaveModal(false)}>
            <Modal.Header>
              <Modal.Title>{templateId ? templateName : 'Saving New Template'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              Please enter the template name to save
              <input type="text" id="templateName" name="templateName" onChange={(e) => setTemplateName(e.target.value)} value={templateName} />
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => closeModal()}>Close</Button>
              <Button variant="primary" onClick={() => saveTemplate()}>Save changes</Button>
            </Modal.Footer>
          </Modal></div> : ''
    }
  </>
);
}

export default App;
